import RegistrationService from '@/services/RegistrationService'
import embedRouter from './embedRouter'
import iframeSource from '@/mixins/iframeSource'
import VueCookies from 'vue-cookies'
import store from './store/store'

const embedMatch = embedRouter.matcher.match(window.location.pathname)
const locale = window.location.href.match(/locale=([a-z]{2}_[A-Z]{2}|[a-z]{2})/)
const email = window.location.href.match(/email=(.*?)($|&)/)
const firstname = window.location.href.match(/firstname=(.*?)($|&)/)
const lastname = window.location.href.match(/lastname=(.*?)($|&)/)
const uniqueid = window.location.href.match(/uniqueid=(.*?)($|&)/)
const xat = window.location.href.match(/x-at=(.*?)($|&)/)
const hash = window.location.href.match(/hash=(.*?)($|&)/)
const countryid = window.location.href.match(/countryid=(.*?)($|&)/)
const next = window.location.href.match(/next=(.*?)($|&)/)
const ccruuid = window.location.href.match(/ccr_app=(.*?)($|&)/)
const redirectUrl = window.location.href.match(/redirectUrl=(.*?)($|&)/)

if (next) {
  const decodedNext = decodeURIComponent(next[1])

  if (decodedNext.match(/^\/[\w/-_?%-]+\/?$/)) {
    VueCookies.set('myfs_next_url', decodedNext, 0)
  }
}

let bindObj = {
  $route: {
    query: {}
  },
  $store: {
    state: {
      ui: {}
    }
  }
}

if (locale) {
  let localeURI = decodeURIComponent(locale[1])
  bindObj['$store'].state.ui.currentLocale = localeURI

  let isShort = localeURI.length === 2 && localeURI.match(/^[a-zA-Z]{2}$/)
  let availableLocales = store.state.ui.availableLocales
  let tmpLocale, shortMatchRegExp

  if (isShort) {
    localeURI = localeURI.toLowerCase()
    shortMatchRegExp = new RegExp(`^${localeURI}_[A-Z]{2}$`)
  }

  for (const availableLocaleEntry of Object.entries(availableLocales)) {
    const availableLocale = availableLocaleEntry[1]
    tmpLocale = availableLocale.locale
    if (
      tmpLocale === localeURI ||
      (isShort && tmpLocale.match(shortMatchRegExp))
    ) {
      VueCookies.set('myfs_locale', tmpLocale, '1y')
      break
    }
  }
}

if (email) {
  bindObj['$route'].query.email = decodeURIComponent(email[1])
}

if (firstname) {
  bindObj['$route'].query.firstname = decodeURIComponent(firstname[1])
}

if (lastname) {
  bindObj['$route'].query.lastname = decodeURIComponent(lastname[1])
}

VueCookies.set('myfs_not_logged_in', 1, 0)

const isRegistration = embedMatch.name === 'embed-register-view'
if (isRegistration) {
  VueCookies.set('myfs_login_from_path', '/register', 0)

  let promotion = embedMatch.params.promotion

  if (!promotion && !hash) {
    let registrationUrl = iframeSource.methods.alterIframeUrlByParams.bind(
      bindObj
    )(process.env.VUE_APP_REGISTER_IFRAME_URL, 'registration')
    window.location.replace(registrationUrl)
  } else {
    const couponCode = embedMatch.params.voucher
    if (!promotion) {
      promotion = 'f-secure-default'
    }
    let registrationParams = {
      promotionKey: promotion,
      couponCode: couponCode,
      firstname: firstname ? decodeURIComponent(firstname[1]) : '',
      lastname: lastname ? decodeURIComponent(lastname[1]) : '',
      uniqueid: uniqueid ? decodeURIComponent(uniqueid[1]) : '',
      'x-at': xat ? decodeURIComponent(xat[1]) : '',
      hash: hash ? decodeURIComponent(hash[1]) : '',
      countryid: countryid ? decodeURIComponent(countryid[1]) : '',
      email: email ? decodeURIComponent(email[1]) : '',
      ccruuid: ccruuid ? ccruuid[1] : ''
    }
    RegistrationService.fetchRegistrationUrlData(registrationParams).then(
      (response) => {
        if (!response || !response.url || response.errorCode) {
          document.body.style.backgroundColor = '#f5f5f5'
          document.body.innerHTML = `<div id="container-iframe" style="padding: 0 20px;font-family: 'FSecureOffice'">
          <div class="iframeContent">
              <h2 style="margin-bottom: 0;font-size: 2rem;font-family:'FSecureOffice';font-weight: 300;color:#333;text-rendering: optimizeLegibility;padding-top: .288em;line-height: 1.21886em;padding-bottom: .288em;">Create account</h2>
                <p style="margin-top:0;color: #333;font-size: 1rem;margin-bottom: 12px;line-height: 1.71429em;font-weight: 400;">Registration or use of this code is not valid in your country or from this internet connection. Please register using the broadband or mobile connection from the provider of your F-Secure service or if you purchased from retail then in the country of purchase.</p>
              </div>
          </div>`
          return
        }
        let finalRegistrationUrl = response.url
        if (promotion === 'amz' && redirectUrl) {
          finalRegistrationUrl =
            finalRegistrationUrl +
            encodeURIComponent(
              `&redirectUrl=${window.btoa(decodeURIComponent(redirectUrl[1]))}`
            )
        }
        let registrationUrl = iframeSource.methods.alterIframeUrlByParams.bind(
          bindObj
        )(finalRegistrationUrl, 'registration')
        window.location.replace(registrationUrl)
      }
    )
  }
} else {
  VueCookies.set('myfs_login_from_path', '/login', 0)
  window.location.replace(
    iframeSource.methods.alterIframeUrlByParams.bind(bindObj)(
      process.env.VUE_APP_LOGIN_IFRAME_URL,
      'login'
    )
  )
}
